import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/expo/:id/:enName/:mark',
        name: 'preview',
        meta:{
            title: '',
            keywords: '',
            description: ''
        },
        component: () => import('../views/landingpage/preview')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('../views/404')
    },
    {
        path: '/:pathMath(.*)*',
        redirect: '/notfound'
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
